/* eslint-disable no-undef */
import React from "react"
import { Auth0Provider } from "@auth0/auth0-react"
import { navigate } from "gatsby"
import "./src/styles/global.scss"
import { AuthEnabledContext } from "./src/AuthContext"
import config from './configs/storefront.json'

const onRedirectCallback = appState => navigate(appState?.returnTo || "/")

export const wrapRootElement = ({ element }) => {
  
  const wrappedElement = React.cloneElement(element, {
    authEnabled: true,
  })

  const { organization_id: organizationId, auth = false} = config;

  return (
    // value here is isAuthEnabled or not
    <AuthEnabledContext.Provider value={auth}>
      <Auth0Provider
        domain={`${process.env.AUTH0_DOMAIN}.us.auth0.com`}
        clientId={process.env.GATSBY_CLIENT_ID}
        redirectUri={window.location.origin}
        organization={organizationId}
        onRedirectCallback={onRedirectCallback}
      >
        {wrappedElement}
      </Auth0Provider>
    </AuthEnabledContext.Provider>
  )
}
